<template>
  <div class="body">
    <div class="container">
      <nav class="topbar" v-if="!getToken">
        <div class="brand">
          <router-link to="/" class="brand-icon">
            <img src="@/assets/Logo@2x.png" class="brand-logo" />
          </router-link>
        </div>
      </nav>
      <div id="texte_a_afficher" class="texte_inselectionnable">
        <p style="text-align: center; font-size: 22px">
          <strong>Mentions légales</strong>
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>Informations légales</strong></span
          >
        </p>
        <p>
          Le site internet « http://batigo.fr/ » est édité par la société Batigo
          au capital de 6 000€, immatriculée au RCS BOBIGNY sous le numéro B 897
          835 849, dont le siège social est localisé au 9 avenue Louise – 93250
          Villemomble.<br />
        </p>
        <p>
          Directeur de Publication : Monsieur Nassim Benali<br />
          E-mail : contact@batigo.fr
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>L’HÉBERGEMENT DU SITE EST ASSUMÉ PAR :</strong></span
          >
        </p>
        <p>
          OVH SARL au capital de 10 174 560 € <br />
          RCS Lille Métropole B 424 761 419 <br />
          SIRET 424 761 419 000 45 <br />
          2 rue Kellermann <br />
          59100 Roubaix
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>RÉCLAMATIONS</strong></span
          >
        </p>
        <dl>
          <dt style="margin-left: 15px">1. Qu’est-ce qu’une réclamation ?</dt>
          <dd style="margin-left: 42px">
            Une réclamation est une déclaration par laquelle vous manifestez
            votre mécontentement envers notre société, sur un ou des sujets
            clairement identifiés, dans l’application de la convention
            d’intermédiation.
          </dd>
          <dt style="margin-left: 15px">2. Comment faire une réclamation ?</dt>
          <dd style="margin-left: 42px">
            La réclamation prend obligatoirement la forme d’un écrit adressé par
            voie postale à l’adresse : <br />
            Batigo <br />
            Service de traitement des réclamations<br />
            9 avenue Louise<br />
            93250 Villemomble
          </dd>
          <dt style="margin-left: 15px">
            3. Comment est traitée votre réclamation ?
          </dt>
          <dd style="margin-left: 42px">
            Nous nous engageons à accuser réception de votre réclamation dans un
            délai maximum de 10 jours ouvrables à compter de sa réception. Nous
            nous engageons à vous apporter une réponse (positive ou négative)
            dans un délai de 2 mois à compter de la réception de la totalité des
            éléments de votre réclamation. Si vous n’êtes pas satisfait de notre
            réponse et si vous êtes un particulier, vous pouvez saisir
            gratuitement le médiateur de la consommation en vue de la résolution
            amiable du litige qui l’oppose au Prestataire, dans les conditions
            prévues au titre 1er du livre VI du Code de la consommation, en
            contactant l’Association des médiateurs européens dont relève le
            Prestataire, dont les coordonnées sont :<br />
            AME CONSO <br />
            Association des médiateurs européens<br />
            boulevard Saint Germain, 75007 PARIS, 09 53 01 02 69<br />
          </dd>
        </dl>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>DROITS D’AUTEUR – COPYRIGHT</strong></span
          >
        </p>
        <p>
          Tous les éléments, notamment les textes, images, photographies,
          illustrations, sons, musiques, mis en ligne sur le site
          http://batigo.fr/ sont, sauf mentions particulières, la propriété de
          la société Batigo. La marque Batigo et son logo sont déposés par la
          société Batigo et donne concession de l’utilisation de la marque à
          Batigo.<br />
        </p>
        <p>
          En conséquence et en application des dispositions du Code de la
          propriété intellectuelle, des dispositions législatives et
          réglementaires de tous pays et des conventions internationales, toute
          représentation et / ou reproduction, intégrale ou partielle, de l’un
          quelconque des éléments mentionnés ci-dessus, faite sans le
          consentement préalable et écrit de la société Batigo, est
          interdite.<br />
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>DROITS D’AUTEUR – COPYRIGHT</strong></span
          >
        </p>
        <p>
          Au cœur de notre métier, pour rechercher pour vous les solutions
          pertinentes parmi une multitude d’offres disponibles sur le marché, se
          situent les données que vous nous confiez pour étudier vos projets et
          votre situation.<br />
        </p>
        <p>
          Par exemple, nous vous présentons des solutions logicielles en
          cohérence avec votre profil de société ou de particulier. En matière
          de propositions logicielles, les abonnements affichés vont tenir
          compte de votre souhait ou de votre type de solutions technique.
          Batigo attache la plus grande importance à la protection des données
          personnelles de ses Utilisateurs et Clients, sur ses sites internet et
          applications mobiles .<br />
        </p>
        <p>
          <span style="font-weight: bold; font-size: 18px"
            ><strong>POLITIQUE DE DONNÉES PERSONNELLES</strong></span
          >
        </p>
        <p>
          Au cœur de notre métier, pour rechercher pour vous les solutions
          pertinentes parmi une multitude d’offres disponibles sur le marché, se
          situent les données que vous nous confiez pour étudier vos projets et
          votre situation.<br />
        </p>
        <p>
          Par exemple, nous vous présentons des solutions logicielles en
          cohérence avec votre profil de société ou de particulier. En matière
          de propositions logicielles, les abonnements affichés vont tenir
          compte de votre souhait ou de votre type de solutions technique.
          Batigo attache la plus grande importance à la protection des données
          personnelles de ses Utilisateurs et Clients, sur ses sites internet et
          applications mobiles.
          <br />
        </p>

        <ol>
          <li>
            <strong>Objet de la présente politique<br /></strong>
            La présente Politique de données personnelles décrit quels types de
            données personnelles Batigo et ses éventuels sous-traitants et
            partenaires peuvent être amenés à traiter. <br /><br />
            Batigo est engagé dans une démarche continue de protection des
            données de ses utilisateurs, en conformité avec la Loi Informatique
            et Libertés du 6 janvier 1978 modifiée (ci- après « LIL ») et le
            Règlement UE (2016/679) Général sur la Protection des Données du 27
            avril 2016 (ci-après « RGPD »). <br /><br />
            Dans le cadre de l’exploitation de son site internet et de son
            application mobile, Batigo collecte des données vous concernant. Ces
            données sont traitées conformément aux finalités prévues lors de la
            collecte, certaines informations vous concernant étant obligatoires
            et d’autres facultatives. <br /><br />
            Batigo est destinataire de ces données personnelles mais peut être
            amené à les transmettre à des tiers tels que des partenaires
            commerciaux, en fonction des consentements recueillis. Batigo pourra
            vous faire parvenir de façon régulière des messages électroniques
            d’informations générales sur des produits analogues à votre demande
            ou des messages électroniques personnalisés, en fonction des
            informations saisies sur notre site.<br /><br />
          </li>
          <li>
            <strong>L’utilisation de vos données<br /></strong>
            Les traitements reposent soit sur le consentement des personnes
            concernées, soit sur la mise en place d’un contrat avec l’une des
            entités de Batigo ou des mesures précontractuelles à la demande de
            l’utilisateur, soit aux fins des intérêts légitimes du Groupe
            Batigo. Par ailleurs, les entités du Groupe Batigo pourraient être
            amenées à collecter des données à caractère personnel pour d’autres
            finalités licites prévues par la Loi et le Règlement Général, en
            prenant soin de recueillir le consentement préalable des personnes
            concernées. <br />
            Les données personnelles sont principalement traitées pour : <br />
            Assurer la meilleure qualité des services de Batigo et la
            présentation des offres et fournir à ses Utilisateurs la meilleure
            analyse de leur profil ; vous envoyer un email ou un SMS de
            confirmation de votre offre, afin que vous en ayez une trace et
            puissiez facilement retrouver l’analyse ou l’information dans le
            futur ; faire le suivi et l’analyse des offres présentées, et la
            mise à jour régulière de ces analyses par exemple dans le cadre de
            mises à jour des prix des offres, <br />
            consultables soit dans votre espace Client, soit en pouvant recevoir
            des mises à jour par email ; envoyer des newsletters régulières
            d’information et sur les offres commerciales du Groupe Batigo et de
            ses partenaires. <br />
            Réaliser la mise en relation avec un conseiller commercial de
            Batigo, constituer un fichier Client pour le suivi et la réalisation
            des prestations des entités du Groupe Batigo ; réaliser la mise en
            relation selon les métiers avec les partenaires pertinents, et
            l’envoi par ces partenaires de propositions commerciales ; gérer
            votre compte dans le cadre de difficultés techniques, questions sur
            des produits ou demandes au service Client. <br /><br />
            L’accès à des simulateurs et des comparateurs personnalisés sur
            l’espace Client, accessible après identification sécurisée ;
            améliorer le fonctionnement des Sites et de ses Applications mobiles
            et des parcours de navigation, et réaliser des statistiques sur
            l’utilisation des outils ; produire des études de marché à
            destination des professionnels (sans qu’aucune donnée personnelle ne
            soit utilisée, mais en utilisant les données des projets par
            exemple) ou des analyses de tendance et des profils des
            consommateurs. <br /><br />
            Recueillir le consentement des Utilisateurs lorsque celui-ci est
            requis par la réglementation en vigueur, notamment en matière de
            prospection commerciale par Batigo et ses partenaires, et en matière
            d’acceptation des cookies ; vous adresser des messages publicitaires
            de Batigo ou de nos partenaires selon votre comportement de
            navigation sur les Services du Groupe Batigo ou votre profil ;
            suivre les ventes et achats sur certains métiers chez nos
            partenaires, ce qui peut nous amener à partager des données avec
            votre fournisseur de produits concernant les services et produits
            que vous avez acheté. <br />
            Réaliser l’analyse de tout ou partie des données vous concernant
            collectées au sein de Batigo, pouvant être croisées avec celles de
            partenaires choisis, pour améliorer nos produits (recherche et
            développement), évaluer votre situation ou la prédire (scores
            d’appétence) et personnaliser votre parcours client (offres et
            publicités ciblées). <br />
            Organiser des jeux-concours, des programmes de fidélité et toutes
            opérations promotionnelles, à l’exclusion des jeux d’argent et de
            hasard en ligne soumis à l’agrément de l’Autorité de Régulation des
            Jeux en Ligne ; gérer les avis, les enquêtes et les sondages des
            Utilisateurs et des Clients sur les Services ou les contenus des
            Sites et des Applications. <br /><br />
            Collecter et gérer les candidatures à un recrutement pour un
            collaborateur ou une création d’agence ; prévenir et lutter contre
            la fraude informatique, le blanchiment des capitaux et le
            financement du terrorisme.<br /><br />
          </li>
          <li>
            <strong>Type de données traitées<br /></strong>
            En particulier, les entités du Groupe Batigo sont susceptibles de
            collecter auprès de vous les données suivantes : <br /><br />
            Des données d’Identité, comme par exemple le nom, le prénom,
            l’adresse postale, le numéro de téléphone (fixe ou mobile),
            l’adresse de courrier électronique, l’adresse postale, la date et le
            lieu de naissance. <br /><br />
            Des données relatives aux inscriptions aux newsletters comme par
            exemple le nom, le prénom, l’adresse de courrier électronique.<br /><br />
            Des données de statistiques de fréquentation comme par exemple
            l’adresse IP, les cookies, les pages consultées par l’utilisateur,
            la date et l’heure de la consultation. Une géolocalisation peut être
            mise en place sur les applications mobiles avec le consentement de
            l’utilisateur. Les données ne seront pas conservées au-delà de la
            navigation. L’utilisateur peut à tout moment désactiver sa
            géolocalisation. La désactivation, selon les services offerts,
            pourrait entrainer des troubles sur le fonctionnement de
            l’application.
            <br />Le caractère obligatoire ou facultatif des données est indiqué
            sur les formulaires de collecte. Les champs facultatifs ont pour
            objectif de mieux vous connaître et ainsi mieux répondre à votre
            demande. Le défaut de réponse dans l’un des champs obligatoires
            entraîne l’impossibilité pour nos services de traiter votre demande.
            <br /><br />
            En tout état de cause, les entités du Groupe Batigo s’engagent à
            traiter l’ensemble des données collectées de manière conforme aux
            réglementations en vigueur et notamment du Règlement n°2016/679
            précité.
            <br /><br />
            Le Site et les Applications mobiles s’adressent à des personnes
            majeures capables de contracter des obligations conformément à la
            législation du pays dans lequel se trouve l’Utilisateur.<br /><br />
          </li>
          <li>
            <strong>Destination des données collectées<br /></strong>
            Les données à caractère personnel collectées sur ce site sont
            réservées aux entités du Groupe Batigo et, le cas échéant, aux
            organismes officiels et aux autorités administratives ou
            judiciaires.
            <br /><br />
            Dans le cas où les entités du Groupe Batigo confient les activités
            de traitement de données à des sous-traitants pour assurer les
            prestations, ces derniers seront notamment choisis pour les
            garanties suffisantes quant à la mise en œuvre des mesures
            techniques et organisationnelles appropriées, notamment en termes de
            fiabilité et de mesures de sécurité. Les données pourront, avec le
            consentement préalable de l’Utilisateur être transmises aux
            partenaires commerciaux des entités du Groupe Batigo. Cet accord
            peut être retiré à tout moment par l’Utilisateur.
            <br /><br />
            Conformément à la réglementation en vigueur, les données peuvent
            être transmises aux autorités compétentes sur requête et notamment
            aux organismes publics, exclusivement pour répondre aux obligations
            légales, les auxiliaires de justice, les officiers ministériels et
            les organismes chargés d’effectuer le recouvrement de créances.
            <br /><br />
            Batigo vous informe que vos données sont susceptibles d’être
            transférées hors Union Européenne, notamment dans le cadre de
            l’étude de votre demande. Le cas échéant, Batigo s’engage à
            transférer les données personnelles hors Union Européenne,
            conformément à la loi n°78-17 du 6 janvier 1978 modifiée et au
            Règlement (UE) 2016/679. Les informations seront transférées à des
            fins de gestion commerciale pour permettre la mise en œuvre des
            prérogatives de Batigo. Les pays destinataires pourront être
            notamment le Maroc, l’Algérie, Madagascar, la Tunisie, l’Indonésie.
            Le transfert peut concerner tout ou partie des informations
            renseignées dans les formulaires de demande.
            <br /><br />
            Les destinataires seront nos sous-traitants et nos partenaires,
            lesquels peuvent avoir des centres d’appels ou des prestataires
            basés à l’étranger. Les pays précités ne disposent pas d’un niveau
            de protection adéquat.<br /><br />
          </li>
          <li>
            <strong>Durée de conservation des données collectées<br /></strong>
            Nous conservons vos données à caractère personnel pendant la durée
            strictement nécessaire aux finalités pour lesquelles elles ont été
            collectées et ce, conformément à la réglementation applicable et
            sous réserve de l’exercice de vos droits. Nous conservons également
            certaines de vos données à caractère personnel, avec un accès
            restreint, afin de remplir nos obligations légales ou réglementaires
            ou à des fins statistiques et ce, pour les durées prévues par les
            recommandations de la CNIL ou par la loi.
            <br />
            Plus précisément, pour votre demande d’étude de dossier pour
            profiter d’un avantage du programme CEE, nous appliquons les durées
            de conservation suivantes : Trois ans dans le cadre de l’utilisation
            de nos services. Suite à la fin de cette relation contractuelle,
            nous conservons vos coordonnées pendant Trois ans pour de la
            prospection commerciale. Suite à ce dernier délai, nous conservons
            vos données, à des fins probatoires pour prescription légale,
            pendant sept ans pour une demande traitée sur le site Internet ou
            par téléphone, et pendant trois ans pour une demande traitée en
            agence. A l’expiration de ces différents délais, vos données sont
            rendues anonymes.<br /><br />
          </li>
          <li>
            <strong>Les cookies<br /></strong>
            Un cookie est un petit fichier texte enregistré par le navigateur de
            votre ordinateur, tablette ou téléphone qui nous permet de garder la
            trace de votre visite. Nous utilisons les cookies pour vous offrir
            une meilleure expérience de navigation, personnalisée et sécurisée.
            <br /><br />
            Si vous n’avez pas paramétré votre navigateur pour accepter ce type
            de fichier, vous n’aurez pas accès à l’ensemble des fonctionnalités
            du site. Certains cookies, sans incidence sur votre accès aux
            fonctionnalités du site, sont également collectés par nous ou nos
            annonceurs privilégiés. Les cookies de mesure d’audience permettent
            de suivre le trafic et ses évolutions.
            <br /><br />
            Pour désactiver le cookie déposé par Xiti (At. Internet),
            <a href="https://www.xiti.com/fr/optout.aspx">cliquez ici</a> Pour
            désactiver le cookie déposé par Realytics,
            <a href="httpss://www.realytics.io/optout/">cliquez ici</a>
            <br />
            Les cookies statistiques permettent d’optimiser le site, de résoudre
            les bugs, d’améliorer la qualité de nos services et la performance
            des pages. <br /><br />
            Les cookies de personnalisation de publicités ou de contenus
            permettent d’afficher des publicités ou des contenus en fonction de
            vos centres d’intérêts. <br /><br />
            Les cookies de diffusion de publicité permettent d’afficher des
            messages publicitaires sur notre site. Pour désactiver le cookie
            déposé par Smart Adserver,
            <a href="https://diff.smartadserver.com/oo?optout">cliquez ici</a>
            Pour désactiver les cookies tiers statistiques et de
            personnalisation de publicité ou contenu,
            <a href="http://batigo.fr/">cliquez ici</a>.
            <br />
            Pour connaître les modalités applicables à la gestion des cookies,
            vous pouvez consulter le menu d’aide de votre navigateur ainsi que
            la rubrique « Vos traces » du site de la CNIL (Commission Nationale
            de l’Informatique & des Libertés). <br /><br />
            Enfin, vous pouvez gérer l’utilisation et l’exploitation de ces
            Cookies en vous rendant sur la plateforme de gestion des Cookies
            publicitaires proposée par les professionnels de la publicité :
            <a href="https://www.youronlinechoices.com/fr/controler-ses-cookies"
              >youronlinechoices.com</a
            >
            <br />
            et en suivant les instructions qui y sont données. Vous pourrez
            ainsi connaître les entreprises inscrites à cette plateforme,
            lesquelles vous offrent la possibilité de refuser ou accepter les
            Cookies utilisés par elles pour adapter à vos informations de
            navigation, les publicités susceptibles d’être affichées.<br /><br />
          </li>
          <li>
            <strong>Vos droits<br /></strong>
            Conformément au Règlement (UE) 2016/679 Général relatif à la
            Protection des Données à caractère Personnel et à la Loi
            Informatique et Libertés du 6 janvier 1978 modifiée, et quel que
            soit le mode de collecte des données personnelles utilisé, vous
            disposez des droits suivants sur vos données : droit d’accès, droit
            de rectification, droit à l’effacement (droit à l’oubli), droit
            d’opposition, droit à la limitation du traitement, droit à la
            portabilité. <br /><br />
            Pour exercer ces droits, un formulaire est à votre disposition dans
            la page <a href="/contact">«Contactez-nous »</a>. Vous pouvez
            également disposer de droits complémentaires nationaux, comme de
            définir le sort de vos données personnelles après votre décès.
            <br /><br />
            Si l’utilisateur souhaite interrompre son abonnement à des
            newsletters, il peut le faire à tout moment à partir des
            newsletters, en cliquant sur le lien prévu à cet effet en bas de
            page. Pour des motifs tenant à sa situation particulière,
            l’Utilisateur peut également s’opposer au traitement des données le
            concernant.
            <br /><br />
            Si vous ne souhaitez pas faire l’objet de prospection commerciale
            par voie téléphonique, vous pouvez vous inscrire gratuitement sur la
            liste d’opposition au démarchage téléphonique BLOCTEL. <br />
            Pour plus d’informations, consultez le site
            <a href="https://www.bloctel.gouv.fr" target="_blank"
              >www.bloctel.gouv.fr</a
            ><br />
            Les conversations téléphoniques avec nos conseillers téléphoniques
            Batigo peuvent être enregistrées, à des fins de formation, en vue de
            l’amélioration de la qualité de nos services. Ces informations sont
            destinées à Batigo, responsable du traitement et sont nécessaires à
            la gestion de votre demande. Vous pouvez vous opposer à cet
            enregistrement en le signalant au conseiller téléphonique. <br />
            Sous réserve d’un manquement aux dispositions ci-dessous,
            l’Utilisateur a le droit d’introduire une réclamation auprès de la
            Commission Nationale de l’Informatique et des Libertés (CNIL)
            <a href="https://www.cnil.fr/" target="_blank">www.cnil.fr</a> ou de
            l’Autorité de Contrôle à la Protection des Données de son lieu de
            résidence.<br /><br />
          </li>
          <li>
            <strong>Sécurité<br /></strong>
            Compte tenu de l’évolution des technologies, des coûts de mise en
            œuvre, de la nature des données à protéger ainsi que des risques
            pour les droits et libertés des personnes, Batigo met en œuvre
            toutes les mesures techniques et organisationnelles appropriées afin
            de garantir la confidentialité des données à caractère personnel
            collectées et traitées et un niveau de sécurité adapté au risque.<br /><br />
          </li>
          <li>
            <strong
              >Modifications de la politique de données personnelles<br
            /></strong>
            En cas de modification de la présente Politique par Batigo, ou si
            des textes légaux ou règlementaires l’exigent, elle sera publiée sur
            nos sites et sera effective dès sa publication. <br />
            Pour toute information sur la protection des données personnelles,
            l’Utilisateur peut également consulter le site de la Commission
            Nationale de l’Informatique et des Libertés (CNIL)
            <a href="https://www.cnil.fr/" target="_blank">www.cnil.fr</a> ou de
            l’Autorité de Contrôle à la Protection des Données de son lieu de
            résidence.
          </li>
        </ol>
      </div>
    </div>
    <!-- <footer>
      <div class="link">
        <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
        <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
        <router-link to="/cgu">{{ $t("C.G.U") }}</router-link>
      </div>
      <p>{{ $t("COPYRIGHT") }}</p>
    </footer> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lang: 0,
    };
  },

  methods: {
    ...mapActions(["changeLang"]),

    chooseLang(lang) {
      this.changeLang(lang);
    },

    close(e) {
      if (this.$refs["langue"])
        if (!this.$refs["langue"].contains(e.target)) {
          this.lang = 0;
        }
    },
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getToken",
      "getSelectedLang",
      "getAllLangue",
      "getActiveLangue",
    ]),
  },

  mounted() {
    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: block;
  width: 100%;
}
.container {
  padding: 15px;
  margin-top: 55px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
  }
}

a {
  color: #e4261b !important;
  text-decoration: underline !important;
}

footer {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  border-top: 13px solid #e4261b;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #28367a;
  height: 92px;
  color: #fff;
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 35%;
    display: flex;
    justify-content: space-between;

    & a {
      text-decoration: underline;
      color: #fff !important;

      &:hover {
        color: #ffffff80;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
